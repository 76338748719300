import type { Ref } from 'react';

export enum LoginFormStage {
	PHONE_NUMBER,
	VERIFICATION_CODE,
}

export interface LoginViewComponentParams {
	loading: boolean;
	authState: { loading: boolean; authenticated: boolean };
	stage: LoginFormStage;
	recaptchaRef: Ref<HTMLDivElement>;
	phoneNumber: string;
	verificationCode: string;
	errorMessage: string;
	submitPhoneNumber: () => void;
	backToPhoneNumber: () => void;
	submitVerificationCode: () => void;
	setPhoneNumber: (phoneNumber: string) => void;
	setVerificationCode: (verificationCode: string) => void;
}
