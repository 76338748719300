import { DateTime } from 'luxon';

export const toFeetAndInches = (valueCm: number) => {
	const inches = Math.round(valueCm * 0.393701);
	const feet = Math.floor(inches / 12);
	const inchesMod = inches % 12;
	return `${feet}'${inchesMod}`;
};

export const toAgeYears = (birthdayEpochSeconds: number) =>
	Math.floor(Math.abs(DateTime.fromSeconds(birthdayEpochSeconds).diffNow('years').years));
