import { FC } from 'react';
import { type ProfileViewParams } from './types';
import { Row, Col, Button, Carousel } from 'react-bootstrap';
import { toFeetAndInches } from '../../utils/strings';

const ProfileView: FC<ProfileViewParams> = ({ data, registerInterest }) => {
	return (
		<Row>
			<Col md={{ span: 10, offset: 2 }} xs={12} className="d-grid gap-3 mx-auto p-4">
				<Row className="text-center">
					<Col>
						<Carousel interval={null}>
							{data.profileImages.map((item, index) => {
								return (
									<Carousel.Item key={`${index}`}>
										<div className="image-placeholder">
											<img
												src={item}
												alt={`profile headshot ${index + 1}`}
												className="img-fluid"
												style={{ maxHeight: '400px' }}
											/>
										</div>
									</Carousel.Item>
								);
							})}
						</Carousel>
					</Col>
				</Row>
				<Row className="text-center">
					<Col>
						<h4>{data.firstName}</h4>
					</Col>
				</Row>
				{registerInterest && (
					<Row className="text-center mt-3 mb-4 gap-3">
						<Col className="p-1">
							<Button className="eventPillButton w-100" onClick={() => registerInterest(false)}>
								Not Interested
							</Button>
						</Col>
						<Col className="p-1">
							<Button className="eventPillButton w-100" onClick={() => registerInterest(true)}>
								Interested
							</Button>
						</Col>
					</Row>
				)}
				<Row>
					<Col className="profileInfoContainer p-5">
						<Row>
							{data.heightCm ? (
								<Col className="profileInfoContainerCol">
									<Row className="text-center">
										<Col>{data.age}</Col>
										<Col>{toFeetAndInches(data.heightCm)}</Col>
									</Row>
								</Col>
							) : (
								<Col className="profileInfoContainerCol">
									<Row>
										<Col className="profileLabel">Age</Col>
									</Row>
									<Row>
										<Col>{data.age}</Col>
									</Row>
								</Col>
							)}
						</Row>
						<Row>
							<Col className="profileInfoContainerCol">
								<Row>
									<Col className="profileLabel">Work</Col>
								</Row>
								<Row>
									<Col>{data.profession}</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col className="profileInfoContainerCol">
								<Row>
									<Col className="profileLabel">Lives</Col>
								</Row>
								<Row>
									<Col>{data.location}</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col className="profileInfoContainerCol">
								<Row>
									<Col className="profileLabel">School</Col>
								</Row>
								<Row>
									<Col>{data.education}</Col>
								</Row>
							</Col>
						</Row>
						{data.religion && data.religion !== 'not_provided' && (
							<Row>
								<Col className="profileInfoContainerCol">
									<Row>
										<Col className="profileLabel">Religion</Col>
									</Row>
									<Row>
										<Col>{data.religion}</Col>
									</Row>
								</Col>
							</Row>
						)}
						<Row>
							<Col className="profileInfoContainerCol">
								<Row>
									<Col className="profileLabel">Career and Ambitions</Col>
								</Row>
								<Row>
									<Col>{data.career}</Col>
								</Row>
							</Col>
						</Row>
						{data.loves.length > 0 && (
							<Row>
								<Col className="profileInfoContainerCol">
									<Row>
										<Col className="profileLabel">Loves</Col>
									</Row>
									<Row>
										<Col>{data.loves.join(', ')}</Col>
									</Row>
								</Col>
							</Row>
						)}
						{data.hates.length > 0 && (
							<Row>
								<Col className="profileInfoContainerCol">
									<Row>
										<Col className="profileLabel">Hates</Col>
									</Row>
									<Row>
										<Col>{data.hates.join(', ')}</Col>
									</Row>
								</Col>
							</Row>
						)}
						{data.firstDateSuggestions.length > 0 && (
							<Row>
								<Col className="profileInfoContainerCol">
									<Row>
										<Col className="profileLabel">First Date Suggestions</Col>
									</Row>
									<Row>
										<Col>{data.firstDateSuggestions.join(', ')}</Col>
									</Row>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ProfileView;
