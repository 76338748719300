import { FC } from 'react';
import { ProtectedRouteComponentParams } from './types';
import LoginView from '../LoginView';
import AdminLoginView from '../AdminLoginView';

const ProtectedRouteComponent: FC<ProtectedRouteComponentParams> = ({
	children,
	isAuthenticated,
	isAdminQuery,
}) => {
	if (isAuthenticated) {
		return <div>{children}</div>;
	}

	if (isAdminQuery) {
		return <AdminLoginView />;
	}

	return <LoginView />;
};

export default ProtectedRouteComponent;
