import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../firebase/AuthContext';
import { useCallback } from 'react';
import { getAuth, signOut } from 'firebase/auth';

const Header = () => {
	const { t } = useTranslation();
	const authContext = useAuthContext();

	const _logout = useCallback(() => {
		if (authContext.authUser) {
			signOut(getAuth());
		}
	}, [authContext]);

	return (
		<Row className="mt-3" style={{ flexShrink: 0 }}>
			<Col>
				<a href={t('website_url')}>
					<div className="navigationLogo" />
				</a>
			</Col>
			<Col className="text-end">
				{Boolean(authContext.authUser) && <Button onClick={_logout}>Logout</Button>}
			</Col>
		</Row>
	);
};

export default Header;
