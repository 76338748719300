import { FC, useEffect, useState, type PropsWithChildren } from 'react';
import { ProtectedRouteComponentParams } from './types';
import { useAuthContext } from '../../firebase/AuthContext';
import { useSearchParams } from 'react-router-dom';

const withProtectedRouteViewModel =
	(Component: FC<ProtectedRouteComponentParams>): FC<PropsWithChildren> =>
	({ children }) => {
		const [isAuthenticated, setIsAuthenticated] = useState(false);
		const [search] = useSearchParams();
		const authContext = useAuthContext();

		useEffect(() => {
			setIsAuthenticated(Boolean(authContext.authUser));
		}, [authContext]);

		const params: ProtectedRouteComponentParams = {
			isAuthenticated,
			children,
			isAdminQuery: search.has('admin'),
		};

		return <Component {...params} />;
	};

export default withProtectedRouteViewModel;
