import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const InvalidUrlView = () => {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{`${t('matchmaking_name')} - Invalid URL`}</title>
			</Helmet>
			<Row>
				<Col>Invalid URL</Col>
			</Row>
		</>
	);
};

export default InvalidUrlView;
