import { FC } from 'react';
import { AdminLoginViewComponentParams } from './types';

import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';

const TwoFactorVerificationForm = ({
	verificationCode,
	verificationId,
	submitVerificationCode,
	setVerificationCode,
}: Pick<
	AdminLoginViewComponentParams,
	'verificationCode' | 'verificationId' | 'submitVerificationCode' | 'setVerificationCode'
>) => {
	return (
		<Row className="my-3">
			<Col>
				{!verificationId ? (
					<Form>
						<Form.Group as={Row} className="my-3">
							<Col>
								<Spinner />
							</Col>
						</Form.Group>
					</Form>
				) : (
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							submitVerificationCode();
						}}
					>
						<Form.Group as={Row} className="my-3">
							<Form.Label column>Verification Code</Form.Label>
							<Col>
								<Form.Control
									name="verifyCode"
									value={verificationCode}
									onChange={(e) => setVerificationCode(e.currentTarget.value)}
									type="text"
									placeholder=""
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="my-3">
							<Col></Col>
							<Col>
								<Button type="submit" name="submitBtn">
									Verify
								</Button>
							</Col>
						</Form.Group>
					</Form>
				)}
			</Col>
		</Row>
	);
};

const SignInForm: FC<AdminLoginViewComponentParams> = ({
	email,
	password,
	errors,
	showTwoFactorForm,
	verificationCode,
	verificationId,
	emailLoginOnSubmit,
	submitVerificationCode,
	setEmail,
	setPassword,
	setVerificationCode,
}) => {
	const isInvalid = password === '' || email === '';
	return (
		<>
			<Form onSubmit={emailLoginOnSubmit}>
				<Form.Group as={Row} className="mb-3">
					<Form.Label column>Email</Form.Label>
					<Col>
						<Form.Control
							type="email"
							name="email"
							id="email"
							placeholder="Email Address"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Col>
				</Form.Group>
				<Form.Group as={Row} className="mb-3">
					<Form.Label column>Password</Form.Label>
					<Col>
						<Form.Control
							type="password"
							name="password"
							id="password"
							placeholder="Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</Col>
				</Form.Group>
				<Form.Group as={Row}>
					<Col>
						<Button disabled={isInvalid} type="submit">
							Sign In
						</Button>
						{!!errors.length && errors.map((error, index) => <p key={index}>{error}</p>)}
					</Col>
				</Form.Group>
			</Form>
			{showTwoFactorForm && (
				<TwoFactorVerificationForm
					{...{
						verificationCode,
						verificationId,
						submitVerificationCode,
						setVerificationCode,
					}}
				/>
			)}
		</>
	);
};

const AdminLoginViewComponent: FC<AdminLoginViewComponentParams> = (params) => {
	return (
		<Container>
			<Row className="pb-5">
				<Col>
					<h1>Admin Sign in</h1>
				</Col>
			</Row>
			<Row className="pb-5">
				<Col>
					<SignInForm {...params} />
				</Col>
			</Row>
			<div ref={params.recaptchaElement}></div>
		</Container>
	);
};

export default AdminLoginViewComponent;
