import { CSSProperties } from 'react';

export const styles: { container: CSSProperties; childrenWrapper: CSSProperties } = {
	container: {
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
	},
	childrenWrapper: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
};
