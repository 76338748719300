import { FC, useEffect, useRef, useState } from 'react';
import { LoginFormStage, LoginViewComponentParams } from './types';
import {
	ConfirmationResult,
	RecaptchaVerifier,
	getAuth,
	signInWithPhoneNumber,
} from 'firebase/auth';
import { useAuthContext } from '../../firebase/AuthContext';

const withLoginViewViewModel = (Component: FC<LoginViewComponentParams>) => () => {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [verificationCode, setVerificationCode] = useState('');
	const recaptchaRef = useRef<HTMLDivElement>(null);
	const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();
	const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
	const [loading, setLoading] = useState(false);
	const authContext = useAuthContext();
	const [authState, setAuthState] = useState({
		loading: authContext.loading,
		authenticated: !!authContext.authUser,
	});
	const [errorMessage, setErrorMessage] = useState<string>('');
	useEffect(() => {
		setAuthState({ loading: authContext.loading, authenticated: !!authContext.authUser });
	}, [authContext]);

	useEffect(() => {
		if (recaptchaRef.current) {
			setRecaptchaVerifier(
				new RecaptchaVerifier(getAuth(), recaptchaRef.current, { size: 'invisible' })
			);
		}
	}, [recaptchaRef]);

	const submitPhoneNumber = () => {
		if (recaptchaVerifier) {
			setLoading(true);
			signInWithPhoneNumber(getAuth(), phoneNumber, recaptchaVerifier)
				.then((confirmationResult) => {
					setErrorMessage('');
					setConfirmationResult(confirmationResult);
				})
				.catch((e) => {
					setErrorMessage(
						'There was a problem processing your phone number. Please check your number accurate and try again'
					);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const backToPhoneNumber = () => {
		setConfirmationResult(undefined);
		setPhoneNumber('');
	};

	const submitVerificationCode = () => {
		if (verificationCode && confirmationResult) {
			setLoading(true);
			setErrorMessage('');
			confirmationResult
				.confirm(verificationCode)
				.then(() => {
					setErrorMessage('');
				})
				.catch((e) => {
					setErrorMessage('Unrecognized verification code.');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const stage = !!confirmationResult
		? LoginFormStage.VERIFICATION_CODE
		: LoginFormStage.PHONE_NUMBER;

	const params: LoginViewComponentParams = {
		loading,
		authState,
		stage,
		recaptchaRef,
		verificationCode,
		phoneNumber,
		errorMessage,
		submitPhoneNumber,
		backToPhoneNumber,
		submitVerificationCode,
		setPhoneNumber,
		setVerificationCode,
	};

	return <Component {...params} />;
};

export default withLoginViewViewModel;
