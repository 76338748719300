import { Col, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const LoadingView = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{`${t('matchmaking_name')} - Loading...`}</title>
			</Helmet>
			<Row>
				<Col md={{ span: 4, offset: 4 }}>
					<Spinner />
				</Col>
			</Row>
		</>
	);
};

export default LoadingView;
