import {
	getAuth,
	getMultiFactorResolver,
	PhoneAuthProvider,
	PhoneMultiFactorGenerator,
	RecaptchaVerifier,
	signInWithEmailAndPassword,
	type MultiFactorResolver,
} from 'firebase/auth';
import { FC, useCallback, useEffect, useRef, useState, type ChangeEvent } from 'react';
import { AdminLoginViewComponentParams } from './types';

const withAdminLoginViewViewModel = (Component: FC<AdminLoginViewComponentParams>) => () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState<string[]>([]);
	const [twoFactorResolver, setTwoFactorResolver] = useState<MultiFactorResolver | null>(null);
	const [showTwoFactorForm, setShowTwoFactorForm] = useState(false);
	const [verificationId, setVerificationId] = useState('');
	const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();
	const [verificationCode, setVerificationCode] = useState('');
	const recaptchaElement = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (recaptchaElement.current) {
			setRecaptchaVerifier(
				new RecaptchaVerifier(getAuth(), recaptchaElement.current, {
					size: 'invisible',
				})
			);
		}
	}, []);

	useEffect(() => {
		if (recaptchaVerifier && twoFactorResolver) {
			const phoneAuthProvider = new PhoneAuthProvider(getAuth());
			var phoneInfoOptions = {
				multiFactorHint: twoFactorResolver.hints[0],
				session: twoFactorResolver.session,
			};
			// Send SMS verification code.
			phoneAuthProvider
				.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
				.then((verificationId) => {
					setVerificationId(verificationId);
				})
				.catch((e) => {
					setErrors([e.message]);
				});
		}
	}, [recaptchaVerifier, twoFactorResolver, setErrors]);

	const submitVerificationCode = useCallback(() => {
		if (recaptchaVerifier && twoFactorResolver) {
			var cred = PhoneAuthProvider.credential(verificationId, verificationCode);
			var multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
			// Complete sign-in.
			return twoFactorResolver.resolveSignIn(multiFactorAssertion).catch((e: unknown) => {
				setErrors([e instanceof Error ? e.message : `${e}`]);
			});
		}
	}, [recaptchaVerifier, twoFactorResolver, verificationCode, verificationId]);

	const emailLoginOnSubmit = useCallback(
		(event: ChangeEvent) => {
			signInWithEmailAndPassword(getAuth(), email, password)
				.then(() => {
					setEmail('');
					setPassword('');
					setErrors([]);
				})
				.catch((error) => {
					if (error.code === 'auth/multi-factor-auth-required') {
						// The user is a multi-factor user. Second factor challenge is required.
						setTwoFactorResolver(getMultiFactorResolver(getAuth(), error));
						setShowTwoFactorForm(true);
					} else {
						setErrors([error.message]);
						setShowTwoFactorForm(false);
						setTwoFactorResolver(null);
					}
				});
			event.preventDefault();
		},
		[email, password]
	);

	const params: AdminLoginViewComponentParams = {
		email,
		password,
		errors,
		twoFactorResolver,
		showTwoFactorForm,
		verificationCode,
		verificationId,
		recaptchaElement,
		emailLoginOnSubmit,
		submitVerificationCode,
		setEmail,
		setPassword,
		setErrors,
		setVerificationCode,
	};

	return <Component {...params} />;
};

export default withAdminLoginViewViewModel;
