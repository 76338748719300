import { getAuth, onAuthStateChanged, type User } from 'firebase/auth';
import {
	FunctionComponent,
	ReactElement,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

export interface AuthUser {
	userId: string;
}

export type Auth = {
	authUser: AuthUser | null;
	loading: boolean;
};

const AuthContext = createContext<Auth>({
	authUser: null,
	loading: false,
});

export const AuthProvider: FunctionComponent<{ children: ReactElement }> = ({ children }) => {
	const [authUser, setAuthUser] = useState<AuthUser | null>(null);
	const [loading, setLoading] = useState(true);
	const authStateChanged = (authState: User | null) => {
		if (!authState) {
			setAuthUser(null);
			setLoading(false);
			return;
		}

		setLoading(true);
		setAuthUser({ userId: authState.uid });
		setLoading(false);
	};

	// listen for Firebase state change
	useEffect(() => {
		return onAuthStateChanged(getAuth(), authStateChanged);
	}, []);

	return <AuthContext.Provider value={{ authUser, loading }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
