import { ChangeEvent, FC, FunctionComponent, type PropsWithChildren } from 'react';
import { Button, Col, Form, FormControl, FormGroup, Row, Spinner } from 'react-bootstrap';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input/mobile';
import { Link } from 'react-router-dom';
import { LoginFormStage, LoginViewComponentParams } from './types';

const Layout: FC<PropsWithChildren<Pick<LoginViewComponentParams, 'recaptchaRef'>>> = ({
	children,
	recaptchaRef,
}) => (
	<Row>
		{children}
		<div>
			<div ref={recaptchaRef} />
		</div>
	</Row>
);

const SubmitPhoneNumberForm: FunctionComponent<
	Pick<
		LoginViewComponentParams,
		'phoneNumber' | 'submitPhoneNumber' | 'errorMessage' | 'setPhoneNumber'
	>
> = ({ phoneNumber, submitPhoneNumber, errorMessage, setPhoneNumber }) => {
	return (
		<Col>
			<Row>
				<Col className="text-center">Enter your number to get started</Col>
			</Row>
			<Row>
				<Col>
					<Form onSubmit={submitPhoneNumber}>
						<Row className="my-3">
							<FormGroup as={Col} className="w-100">
								<PhoneInput
									autoFocus
									defaultCountry="US"
									autoComplete="tel-national"
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e || '')}
								/>
								<p className="text-danger">{errorMessage}</p>
							</FormGroup>
						</Row>
						<Row className="my-3">
							<Col>
								<Button
									type="submit"
									className="eventPillButton d-block w-100"
									disabled={!isPossiblePhoneNumber(phoneNumber || '')}
								>
									Continue
								</Button>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>

			<p className="mt-0 mb-0 text-center" style={{ fontSize: '8pt' }}>
				Msg & data rates may apply
			</p>
		</Col>
	);
};

const SubmitVerificationCodeForm: FunctionComponent<
	Pick<
		LoginViewComponentParams,
		| 'verificationCode'
		| 'errorMessage'
		| 'submitVerificationCode'
		| 'backToPhoneNumber'
		| 'setVerificationCode'
	> & {
		phoneNumber: string;
	}
> = ({
	verificationCode,
	errorMessage,
	phoneNumber,
	backToPhoneNumber,
	submitVerificationCode,
	setVerificationCode,
}) => {
	return (
		<Col>
			<Row>
				<Col className="text-center mb-3">
					<h4 className="fw-bold">Enter your code</h4>
				</Col>
			</Row>
			<Row className="text-left">
				<Col>
					<p>Enter your code sent to {phoneNumber}</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form onSubmit={submitVerificationCode}>
						<Row className="mb-3">
							<FormGroup as={Col}>
								<FormControl
									autoFocus
									type="text"
									autoComplete="one-time-code"
									inputMode="numeric"
									value={verificationCode}
									onChange={(event: ChangeEvent<HTMLInputElement>) =>
										setVerificationCode(event.currentTarget.value.replaceAll(/\D/g, ''))
									}
								/>
								<p className="text-danger">{errorMessage}</p>
							</FormGroup>
						</Row>
						<Row>
							<FormGroup as={Col}>
								<Button className="eventPillButton d-block w-100" type="submit">
									Submit
								</Button>

								<Col className="mt-3">
									<Row className="text-center">
										<Link className="link-primary" to={'#'} onClick={backToPhoneNumber}>
											Entered the wrong number?
										</Link>
									</Row>
								</Col>
							</FormGroup>
						</Row>
					</Form>
				</Col>
			</Row>
		</Col>
	);
};

const LoginViewComponent: FC<LoginViewComponentParams> = ({
	loading,
	authState,
	stage,
	recaptchaRef,
	phoneNumber,
	verificationCode,
	errorMessage,
	submitPhoneNumber,
	backToPhoneNumber,
	submitVerificationCode,
	setPhoneNumber,
	setVerificationCode,
}) => {
	if (authState.loading || authState.authenticated || loading) {
		return (
			<Layout recaptchaRef={recaptchaRef}>
				<Spinner />
			</Layout>
		);
	}

	if (stage === LoginFormStage.PHONE_NUMBER) {
		return (
			<Layout recaptchaRef={recaptchaRef}>
				<SubmitPhoneNumberForm
					phoneNumber={phoneNumber}
					submitPhoneNumber={submitPhoneNumber}
					errorMessage={errorMessage}
					setPhoneNumber={setPhoneNumber}
				/>
			</Layout>
		);
	}

	if (stage === LoginFormStage.VERIFICATION_CODE) {
		return (
			<Layout recaptchaRef={recaptchaRef}>
				<SubmitVerificationCodeForm
					verificationCode={verificationCode}
					errorMessage={errorMessage}
					phoneNumber={phoneNumber}
					backToPhoneNumber={backToPhoneNumber}
					submitVerificationCode={submitVerificationCode}
					setVerificationCode={setVerificationCode}
				/>
			</Layout>
		);
	}
	return null;
};

export default LoginViewComponent;
