import { FC, PropsWithChildren } from 'react';
import Header from './Header';
import { Container } from 'react-bootstrap';
import Footer from './Footer';
import { styles } from './styles';

const Layout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Container style={styles.container}>
			<Header />
			<div style={styles.childrenWrapper}>{children}</div>
			<Footer />
		</Container>
	);
};

export default Layout;
