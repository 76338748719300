import { getAuth, getIdToken } from 'firebase/auth';

export enum ConciergeInterestRequestStatus {
	OPEN = 'OPEN',
	FOLLOW_UP_SENT = 'FOLLOW_UP_SENT',
	INTERESTED = 'INTERESTED',
	NOT_INTERESTED = 'NOT_INTERESTED',
	CLOSED = 'CLOSED',
	CLIENT_CONTACTED = 'CLIENT_CONTACTED',
	CLIENT_INTERESTED = 'CLIENT_INTERESTED',
	CLIENT_NOT_INTERESTED = 'CLIENT_NOT_INTERESTED',
	CONNECTED = 'CONNECTED',
}

export type FetchProfileResponseModel = {
	status: ConciergeInterestRequestStatus;
	profile?: {
		profileImages: string[];
		firstName: string;
		location: string;
		age: number;
		heightCm: number;
		profession: string;
		education: string;
		religion?: string;
		career: string;
		loves: string[];
		hates: string[];
		firstDateSuggestions: string[];
	};
};

type InterestResponseResponseModel = { success: boolean; error?: { message: string } };

export const registerCandidateInterest = async (
	token: string,
	interested: boolean
): Promise<InterestResponseResponseModel> => {
	try {
		const url = `${process.env.REACT_APP_API_HOST}/matchmaker/interest/${token}/response`;
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
			body: JSON.stringify({
				response: interested
					? ConciergeInterestRequestStatus.INTERESTED
					: ConciergeInterestRequestStatus.NOT_INTERESTED,
			}),
		});
		if (response.ok || response.status === 400) {
			return await response.json();
		}
	} catch (e) {
		console.log('failed to register interest', e);
	}
	return { success: false };
};

export const registerClientInterest = async (
	token: string,
	interested: boolean
): Promise<InterestResponseResponseModel> => {
	try {
		const currentUser = getAuth().currentUser;
		if (!currentUser) {
			return { success: false };
		}
		const authToken = await getIdToken(currentUser);
		const url = `${process.env.REACT_APP_API_HOST}/matchmaker/interest/${token}/response`;
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
				Authorization: `Bearer ${authToken}`,
			},
			body: JSON.stringify({
				response: interested
					? ConciergeInterestRequestStatus.CLIENT_INTERESTED
					: ConciergeInterestRequestStatus.CLIENT_NOT_INTERESTED,
			}),
		});
		if (response.ok || response.status === 400) {
			return await response.json();
		}
	} catch (e) {
		console.log('failed to register interest', e);
	}
	return { success: false };
};

export const getProfileForCandidate = async (
	token: string
): Promise<FetchProfileResponseModel | undefined> => {
	try {
		const url = `${process.env.REACT_APP_API_HOST}/matchmaker/interest/${token}/client`;
		const response = await fetch(url, { method: 'GET' });

		if (response.ok) {
			const data: FetchProfileResponseModel = await response.json();
			return data;
		}
	} catch (e) {
		console.log('failed to fetch profile', e);
	}
	console.warn('profile not found');
};

export const getProfileForClient = async (
	token: string
): Promise<FetchProfileResponseModel | undefined> => {
	const currentUser = getAuth().currentUser;
	if (!currentUser) {
		return;
	}
	const authToken = await getIdToken(currentUser);
	const url = `${process.env.REACT_APP_API_HOST}/matchmaker/interest/${token}/candidate`;
	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: `Bearer ${authToken}`,
		},
	});

	if (response.ok) {
		const data: FetchProfileResponseModel = await response.json();
		return data;
	}
};
