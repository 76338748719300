import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const ClosedView = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{`${t('matchmaking_name')} - Link Expired`}</title>
			</Helmet>
			<Row>
				<Col>This link has expired.</Col>
			</Row>
		</>
	);
};

export default ClosedView;
