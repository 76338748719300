import type { ComponentType } from 'react';
import ProtectedRouteComponent from './component';
import withProtectedRouteViewModel from './viewModel';

const ProtectedRoute = withProtectedRouteViewModel(ProtectedRouteComponent);
export default ProtectedRoute;

export const withAuthRequired = (Component: ComponentType) => () =>
	(
		<ProtectedRoute>
			<Component />
		</ProtectedRoute>
	);
